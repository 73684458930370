import React, { useEffect } from 'react';
import logo from "./../../assets/images/Logo.png";
import { useNavigate } from 'react-router-dom';

const UserNotFound = () => {
    const navigate = useNavigate()
    useEffect(()=>{
        setTimeout(()=>{
            (()=>{
                navigate("/signup")
            })()
        }, 3000)
    }, [])
  return (
    <div style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        padding: "20px"
    }}>
        <img style={{ width: "280px", height: "auto" }} src={logo} alt="Logo" />
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px"
        }}>
            <p style={{
                textAlign: "center",
                fontSize: "1.7rem", // Adjust font size here
                fontWeight: "600",
                color: "#DD417A"
            }}>User not found</p>
            <p style={{
                textAlign: "center",
                fontSize: "1.5rem", // Adjust font size here
                color: "#234b68"
            }}>Please Sign up with Google first.</p>
        </div>
    </div>
  );
}

export default UserNotFound;
