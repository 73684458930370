import React from 'react';
import LazyComponent from './pages/Layout/LazyComponent';
import { Navigate } from 'react-router-dom';
import StripePayment from './pages/Payment/stripePayment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import GoogleAuthSuccess from './pages/GoogleAuthSuccess/GoogleAuthSuccess';
import ChangePassword from './pages/Auth/ChangePassword';
import LoginFirstToBePro from './pages/LoginFirstToBePro/LoginFirstToBePro';
import UserNotFound from './pages/UserNotFound/UserNotFound';

const Home = React.lazy(() => import('./pages/Home/Home'));
const Dashboard = React.lazy(()=>import('./pages/Dashboard/Dashboard'))
const AuthLayout = React.lazy(() => import('./pages/Layout/AuthLayout'));
const DashboardLayout = React.lazy(() => import('./pages/Layout/DashboardLayout'));
const SignIn = React.lazy(() => import('./pages/Auth/SignIn'));
const SignUp = React.lazy(() => import('./pages/Auth/SignUp'));
const VerifyMail = React.lazy(() => import('./pages/Auth/VerifyMail'));
const ForgotPassword = React.lazy(() => import('./pages/Auth/ForgotPassword'));
const SocialLogin = React.lazy(() => import('./pages/Auth/SocialLogin'));
const Becomepro = React.lazy(()=>import('./pages/Auth/becomePro'))
const CreateAccount = React.lazy(()=>import('./pages/Auth/createAccount'))
const SignupSuccess = React.lazy(()=>import('./pages/Auth/SignupSuccess'))

const stripePromise=loadStripe("pk_test_51OIYBnKAa95jGs91yLdleVViFEPL9gcH6lh1E4yQtghZrWPY3bgnIcytIETNUfMJSv77u2hqrwFl8P0B1qLhi63R00SW1LEnzI")


const AppRoutes = [
  {
    path: "/",
    element: <LazyComponent><Home /></LazyComponent>,
  },
  {
    path: "/",
    element: <LazyComponent><AuthLayout /></LazyComponent>,
    children: [
      {
        path: '/oauth',
        element: <SocialLogin />
      },
      {
        path: '/signin',
        element: <SignIn />
      },
      {
        path: "/signup",
        element: <SignUp />
      },
      {
        path: "/verify",
        element: <VerifyMail />
      },
      {
        path: "/forgotpassword",
        element: <ForgotPassword />
      },
      {
        path: "/becomepro",
        element: <Becomepro />
      },
      {
        path: "/subscribe",
        element: <Elements stripe={stripePromise}><StripePayment /></Elements>
      },
      {
        path: "/createaccount",
        element: <CreateAccount />
      },
      {
        path: "change-password",
        element: <ChangePassword/>
      }
      
    ]
  },
  {
    path: "/dashboard",
    element: <LazyComponent><DashboardLayout /></LazyComponent>,
    children: [
        {
            index: true,
            element: <Navigate to="/"/>
        },
        {
            path: ":id",
            element:<Dashboard />
        },
        
    ]
  },
  {
    path:"/signupsuccess",
    element:<LazyComponent><SignupSuccess /></LazyComponent>
  },
  {
    path:"/google-auth-success",
    element:<LazyComponent><GoogleAuthSuccess /></LazyComponent>

  },
  {
    path:"/login-first",
    element:<LazyComponent><LoginFirstToBePro /></LazyComponent>

  },
  {
    path:"/signup-first",
    element:<LazyComponent><UserNotFound /></LazyComponent>

  },
];

export default AppRoutes;
