import React from "react";
import Spinner from "./Spinner"

const LazyComponent = ({ children }) => {
  return (
    <React.Suspense fallback={<Spinner visible={true} />}>
      <div className="bg-[#e9edf0] min-h-[100vh]">
      {children}
      </div>
    </React.Suspense>
  );
}

export default LazyComponent;