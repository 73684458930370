import { SET_CREDENTIAL, LOG_OUT } from "../type";

const initialState = {
  isAuthenticated: false,
  user: null
}

const authReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch(type) 
  {
    case SET_CREDENTIAL:
      localStorage.setItem('_token', payload.accessToken);
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      }
    case LOG_OUT:
      localStorage.removeItem('_token');
      return {
        ...state,
        isAuthenticated: false,
        user: null 
      }
    default:
      return state;
  }
}

export default authReducer