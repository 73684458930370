import axios from 'axios'
import { LOG_OUT, SET_CREDENTIAL } from '../type';

export const login = async (email, password) => {
  try{
    const sessionId = localStorage.getItem('sessionId');
    const userResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login`, { email, password, sessionId });
    return userResponse;
  } catch (err) { 
    return false;
  }
}
export const register = async (email, password, first_name, last_name) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/signup`, { email, password, first_name, last_name, role: 0, is_deleted: false });
    return response;
  } catch (err) {
    return err
  }
}

export const oAuth = async (token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', config);
    if(res.data.email_verified) {
      let data = res.data;
      data.sessionId = localStorage.getItem('sessionId');
      const userResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/oauth`, data);
      return userResponse.data;
    } else {
      return { code: 400, message: 'This email is not verified.' };
    }
  } catch (err) {
    return err;
  }
}

export const setCredential = (data) => {
  localStorage.removeItem('sessionId');
  return { type: SET_CREDENTIAL, payload: data };
}

export const Logout = () => {
  return { type: LOG_OUT };
}

export const check_login = async (_token) => {
  try {
    const userResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/check_login`, { _token });
    return userResponse;
  } catch (err) {
    return false;
  }
}

export const verifySendMail = async (email, name) => {
  try{
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sendVerificationMail`, { email, name });
    return response;
  } catch(err) {
    return false;
  }
}

export const verifiedUser = async (_id) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/verifiedUser`, { _id });
    return response;
  } catch (err) {
    return false;
  }
}

export const resetPassword = async (email) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/resetPassword`, { email });
    return response;
  } catch (err) {
    return false;
  }
}