import React, { useState, useEffect } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const StripePayment = () => {
 const stripe = useStripe();
 const elements = useElements();
 const [email,setEmail] = useState('');
 const [phone,setPhone] = useState('');
 const [name,setName] = useState('');
 const [address,setAddress] = useState('');
 const [zipCode,setZipCode] = useState(''); 
 const [city,setCity] = useState('');
 const [country,setCountry] = useState('');

 const [clientSecret, setClientSecret] = useState(null);
 const navigate =useNavigate()
 const token=localStorage.getItem("_token")
 const isYearly=localStorage.getItem('activeTab')==='yearly'
 const [isLoading,setIsLoading]=useState(false)

 useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BACKEND_URL}/process-payment`,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('_token')}`
          },
          data: {
            "token": token,
            "isYearly": isYearly
          }
        });
        setClientSecret(response.data.client_secret); 
      } catch (error) {
        toast.error(error);
      }
    };
    fetchClientSecret(); 
 }, []); 

 const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    if(!clientSecret){
      toast.error('clientSecret is not available');
      return;
    }
    if (!stripe || !elements) { 
      toast.error('Stripe.js has not yet loaded, elements not available');
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });
    if(result.error){
      toast.error(result.error.message);
    }else{
    const token=localStorage.getItem("_token")
      const data = {
        "token":token,
        "email": email ?? "",
        "phone": phone ?? "",
        "name": name ?? "",
        "address": address ?? "",
        "zipCode": zipCode ?? "",
        "city": city ?? "",
        "country": country ?? ""
      }
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/confirm-payment`,
        data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('_token')}`
        },
      });
      if(response.data.code === 200){
        toast.success("Payment successful");
        setTimeout(()=>{navigate('/signupsuccess')},1000)
      }
      else{
        toast.error("Unable to save billing info");
      }
    }
 };

 return (
  <div className="flex flex-col md:flex-row justify-center items-start md:items-start p-8 pt-16 gap-8">
    <div className="p-6 w-full md:w-1/2 text-[var(--main-color-2)] poppins">
      <p className="font-semibold text-xl mb-2">Premium Design Timelapse</p>
      <div className="text-3xl font-bold mb-4">{`${isYearly?"€49.99":"€4.99"}`} <span className='text-sm'>{`per ${isYearly?'year':'month'}`}</span></div>
      <div className="border-t border-gray-200 pt-4 mt-4">
        <div className="flex justify-between">
          <span>Subtotal</span>
          <span>{`${isYearly?"€49.99":"€4.99"}`}</span>
        </div>
        <div className="flex justify-between">
          <span>Tax</span>
          <span>€0.00</span>
        </div>
        <div className="flex justify-between font-bold mt-4">
          <span>Total</span>
          <span>{`${isYearly?"€49.99":"€4.99"}`}</span>
        </div>
      </div>
    </div>

    <div className="p-6 w-full md:w-1/2">
      <h3 className="font-semibold text-xl mb-6 text-[var(--main-color-2)] poppins">Subscribe to Premium</h3>
      <form>
        <div className="mb-4">
          <CardNumberElement className="bg-white w-full p-2 border border-gray-300 rounded-md bg-slate-50"/>
        </div>
        <div className="mb-4">
          <CardCvcElement className="bg-white w-full p-2 border border-gray-300 rounded-md bg-slate-50" />
        </div><div className="mb-4">
          <CardExpiryElement className="bg-white w-full p-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-4">
          <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" className="w-full p-1 px-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-4">
          <input type="text" onChange={(e)=>setName(e.target.value)} placeholder="Name" className="w-full p-1 px-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-4">
          <input type="number" onChange={(e)=>setPhone(e.target.value)} placeholder="Phone" className="w-full p-1 px-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-4">
          <input type="text" onChange={(e)=>setAddress(e.target.value)} placeholder="Address" className="w-full p-1 px-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-4">
          <input type="text" onChange={(e)=>setZipCode(e.target.value)} placeholder="Zip Code" className="w-full p-1 px-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-4">
          <input type="text" onChange={(e)=>setCity(e.target.value)} placeholder="City" className="w-full p-1 px-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-4">
          <input type="text" onChange={(e)=>setCountry(e.target.value)} placeholder="Country" className="w-full p-1 px-2 border border-gray-300 rounded-md bg-slate-50" />
        </div>
        <div className="mb-6">
          <button onClick={handleSubmit} type="button" className="w-full bg-[var(--main-color-1)] hover:bg-[#b21058] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline rounded-lg poppins" disabled={isLoading}>
          {isLoading?"Payment Processing":"Subscribe!"}
          </button>
        </div>
      </form>
    </div>

  </div>
);
}

export default StripePayment;
