import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import AppRoutes from "./AppRoutes";
import { store } from "./redux/store";
import "./App.css";
import { toast } from "react-toastify";
import { check_login, setCredential, Logout } from "./redux/actions/authAction";

function App() {
  const pages = useRoutes(AppRoutes);
  useEffect(() => {
    const _token = localStorage.getItem('_token');
    if(_token) {
      check_login(_token)?.then(res => {
        if(!res) {
          toast.error("Some issues are occured on network");
          return;
        }
        if(!res.data.success) {
          toast.error(res.data.message);
          store.dispatch(Logout());
          return;
        }
        store.dispatch(setCredential(res.data));
      }).catch(err => console.log(err));
    }
  }, [])

  return <Provider store={store}>{pages}</Provider>;
}

export default App;